<template>
  <div class="beamlines">
    <IDBeamline class="id"/>
    <BMBeamline class="bm"/>
  </div>
</template>

<script>
import BMBeamline from './BMBeamline.vue';
import IDBeamline from './IDBeamline.vue';

export default {
  name: 'BeamlineDashboard',
  components: { IDBeamline, BMBeamline },
};
</script>

<style scoped>
.beamlines {
  flex-flow: row wrap;
  color: white;
}

.beamlines > * {
  height: fit-content;
  display: flex;
  box-sizing: border-box;
  padding: 15px;
  width: 100%;
  font-family: 'Helvetica Neue', sans-serif;
  color: black;
  background-color: #1d1e1f;
  border-radius: 5px
}
@media (max-width: 800px) {
  .bm {
    margin: 0 0 -5px -0px;
  }
  .id {
    margin: -5px 0 5px 0;
  }
}

@media (min-width: 800px) and (max-width: 1400px) {
  .beamlines > * {
    width: 50%;
  }
  .bm {
    margin: -5px 0 -5px 0;
    height: auto;
  }
  .id {
    margin: -5px 5px -5px -5px;
  }
}

@media (min-width: 1400px) {
  .beamlines > * {
    width: 50%;
    /*height: auto;*/
  }
  .bm {
    margin: -4px 0 -5px 0;
  }
  .id {
    margin: -5px 0 5px 0;
  }
}
</style>
