<template>
  <div class="message">
    <TextMessageDashboard class="txt"/>
    <OperatorMessage class="op"/>
  </div>
</template>

<script>
import TextMessageDashboard from './TextMessageDashboard.vue';
import OperatorMessage from './OperatorMessage.vue';

export default {
  name: 'MessageDashboard',
  components: { TextMessageDashboard, OperatorMessage },
};
</script>

<style scoped>
.message {
  flex-flow: row wrap;
  color: white;
  align-content: space-around;
}

.message > * {
  background: #1d1e1f;
  display: flex;
  box-sizing: border-box;
  padding: 5px 15px 0 15px;
  width: 100%;
  font-family: 'Helvetica Neue', sans-serif;
  color: white
}

@media (max-width: 950px) {
  .txt {
    margin: -5px 0 5px 0;
  }

  .op {
    margin: 0 0 -5px 0;
    padding-top: 10px;
  }
}

@media (min-width: 950px) and (max-width: 1400px) {
  .message > * {
    box-sizing: border-box;
    width: calc(50% - 5px);
  }

  .txt {
    margin: -3px 5px -5px 0;
  }

  .op {
    margin: -5px 0 -5px 0;
  }
}

@media (min-width: 1400px) {
  .message > * {
    width: 100%;
    box-sizing: border-box;
  }

  .op {
    margin: 15px 0 -5px 0;
  }

  .txt {
    margin: -5px 0 5px 0;
  }
}
</style>
