<template>
  <div class="opMsg">
    <div v-if="isExpanded"
         class="popWindow">
      <div class="scrollWindow" >
        <b-table borderless
                 :fields="fields"
                 :items="getFormattedOperatorMessage">
        </b-table>
      </div>
      <div class="button">
        <b-button size="sm"
                  variant="outline-warning"
                  v-on:click="changeOPState()">
          <b-icon icon="chevron-compact-up"></b-icon>
        </b-button>
      </div>
    </div>
    <div v-else class="opEl">
      <h3 class="opTitle">OPERATOR MESSAGE</h3>
      <b-table  borderless
                :fields="oneField"
                :items="new Array(getFormattedOperatorMessage[0])">
      </b-table>
      <div class="button">
      <b-button size="sm"
                variant="outline-warning"
                v-on:click="changeOPState()">
        <b-icon icon="chevron-compact-down"></b-icon>
      </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'OperatorMessage',
  data() {
    return {
      isExpanded: false,
      oneField: [
        {
          key: 'date',
          tdClass: 'textLeft whiteFont smallCell fontOP',
          thClass: 'noDisplay',
        },
        {
          key: 'time',
          tdClass: 'textLeft whiteFont smallCell fontOP',
          thClass: 'noDisplay',
        },
        {
          key: 'msg',
          tdClass: 'textLeft whiteFont fontOP w-75',
          thClass: 'noDisplay',
        }],
      fields: [
        {
          key: 'date',
          tdClass: 'textLeft whiteFont smallCell smallFontOP',
          thClass: 'noDisplay',
        },
        {
          key: 'time',
          tdClass: 'textLeft whiteFont smallCell fontOP',
          thClass: 'noDisplay',
        },
        {
          key: 'msg',
          tdClass: 'textLeft whiteFont smallFontOP w-75',
          thClass: 'noDisplay',
        }],
    };
  },
  methods: {
    changeOPState() {
      this.isExpanded = !this.isExpanded;
    },
  },
  computed: mapGetters(['getOperatorMessage', 'getFormattedOperatorMessage']),

};
</script>

<style scoped>

.opMsg{
  display:flex;
  flex-flow: column wrap;
  overflow: hidden;
  height: 55%;
  min-height: 150px;
}

.opEl{
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  height: 90%;
  width: 100%;
}

.popWindow{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: space-between;
}

.scrollWindow{
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.opTitle{
  text-align: center;
  color: #ff9124;
  width: 100%;
}

.button{
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
</style>
