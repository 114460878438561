<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import * as Highcharts from 'highcharts/highstock';
import Moment from 'moment';
import MomentTimeZone from 'moment-timezone';
import { DATA_TIME_RANGE, DEVICE } from '../constants.js';

window.moment = Moment;
MomentTimeZone();

export default {
  name: 'HighChart',
  data() {
    return {
      chart: undefined,
      updateInterval: 5000,
    };
  },
  computed: mapGetters(['getCurrent', 'getLifetime', 'getConnectionState']),
  methods: {
    createChart() {
      Highcharts.setOptions({
        lang: {
          rangeSelectorZoom: '',
        },
        time: {
          timezone: 'Europe/Warsaw',
        },
      });
      this.chart = Highcharts.stockChart(this.$el, {
        chart: {
          backgroundColor: '#1d1e1f',
        },
        credits: {
          enabled: false,
        },
        navigator: {
          enabled: false,
        },
        title: {
          text: '',
        },
        rangeSelector: {
          buttonTheme: {
            fill: 'none',
            stroke: 'none',
            style: {
              color: '#CCC',
            },
            states: {
              hover: {
                fill: '#5f5e5e',
                style: {
                  color: '#CCC',
                },
              },
              select: {
                fill: '#5f5e5e',
                style: {
                  color: '#CCC',
                },
              },
            },
          },
          buttons: [{
            count: 4,
            type: 'hour',
            text: '4H',
          }, {
            count: 8,
            type: 'hour',
            text: '8H',
          }, {
            count: 12,
            type: 'hour',
            text: '12H',
          }, {
            count: 16,
            type: 'hour',
            text: '16H',
          }, {
            count: 24,
            type: 'hour',
            text: '24H',
          }, {
            count: 48,
            type: 'hour',
            text: '48H',
          }, {
            count: 72,
            type: 'hour',
            text: '72H',
          }],
          inputEnabled: false,
          selected: 3,
        },
        legend: {
          enabled: true,
          align: 'right',
          backgroundColor: 'none',
          borderColor: '#717174',
          borderWidth: 1,
          verticalAlign: 'top',
          x: -50,
          y: 0,
          shadow: true,
          itemStyle: {
            color: 'white',
            fontSize: '15px',
            fontWeight: 'normal',
          },
          itemHiddenStyle: {
            color: '#A6B0A1',
          },
          itemHoverStyle: {
            color: '#A6B0A1',
          },
        },
        tooltip: {
          valueDecimals: 2,
        },
        responsive: {
          rules: [{
            condition: {
              maxWidth: 600,
            },
            chartOptions: {
              legend: {
                align: 'center',
                verticalAlign: 'bottom',
              },
            },
          }],
        },
        scrollbar: {
          barBackgroundColor: '#3e4042',
          barBorderRadius: 0,
          barBorderWidth: 0,
          buttonBackgroundColor: '#1d1e1f',
          buttonBorderWidth: 0,
          buttonBorderRadius: 0,
          trackBackgroundColor: '#1d1e1f',
          trackBorderWidth: 1,
          trackBorderRadius: 0,
          trackBorderColor: '#3e4042',
        },
        xAxis: {
          type: 'datetime',
          labels: {
            overflow: 'justify',
            style: {
              color: 'white',
            },
          },
        },
        yAxis: [
          {
            title: {
              text: 'Current [mA]',
              style: {
                color: '#288AC0',
                fontSize: '20px',
              },
            },
            labels: {
              overflow: 'justify',
              x: -7,
              style: {
                color: '#288AC0',
              },
              format: '{value}',
            },
            min: 0,
            max: 520,
            gridLineWidth: 0.2,
            minorGridLineWidth: 0.1,
            tickInterval: 100,
            showLastLabel: true,
            showFirstLabel: true,
            opposite: false,
            endOnTick: false,
          },
          {
            title: {
              text: 'Lifetime [h]',
              style: {
                color: '#8cbf6d',
                fontSize: '20px',
              },
            },
            labels: {
              x: 7,
              style: {
                color: '#8cbf6d',
              },
              format: '{value}',
            },
            min: 0,
            max: 52,
            gridLineWidth: 0.1,
            tickInterval: 10,
            showLastLabel: true,
            showFirstLabel: true,
            showEmpty: false,
            endOnTick: false,
          }],
        series: [{
          name: 'Current',
          type: 'area',
          fillColor: 'rgba(40,138,192,0.69)',
          connectNulls: true,
          tooltip: {
            valueSuffix: ' mA',
          },
          shadow: false,

        },
        {
          name: 'Lifetime',
          type: 'line',
          yAxis: 1,
          color: '#8cbf6d',
          connectNulls: true,
          visible: false,
          tooltip: {
            valueSuffix: ' h',
          },
        }],
      });
    },
    fillDataPoints(data, interval) {
      // Method to fill data from archivization in case of live data being fetched with smaller
      // interval, it prevents the chart from losing proper scaling
      const newData = [];

      for (let i = 0; i < data.length - 1; i += 1) {
        const [t0, value0] = data[i];
        const [t1, value1] = data[i + 1];
        const timeDiff = t1 - t0;

        if (timeDiff >= interval) {
          const numNewPoints = Math.floor(timeDiff / interval);
          const valueDiff = value1 - value0;
          const intervalRatio = interval / timeDiff;

          newData.push([t0, value0]);

          for (let j = 1; j <= numNewPoints; j += 1) {
            const newTime = t0 + (j * timeDiff) / (numNewPoints + 1);
            const newValue = value0 + (j * valueDiff * intervalRatio);
            newData.push([newTime, newValue]);
          }
        } else {
          newData.push([t0, value0]);
        }
      }

      newData.push(data[data.length - 1]);
      return newData;
    },
    async fetchData(attr, no) {
      const queryStartTime = new Date();
      queryStartTime.setHours(queryStartTime.getHours() - DATA_TIME_RANGE);
      const query = {
        device: DEVICE,
        attribute: `${attr}`,
        start: queryStartTime.toISOString(),
        decimate: 60,
      };
      const data = await axios.post('/tgarch', query)
        .then((res) => res.data.data.map((point) => {
          const date = new Date(point.time).getTime() + 3600;
          const value = point.value > 0 ? parseFloat(point.value.toFixed(2)) : 0;
          return [date, value];
        }))
        .catch((err) => {
          // eslint-disable-next-line
          console.error(`An error occurred during fetching chart data. Details: ${err}`);
          return [];
        });
      const filledData = this.fillDataPoints(data, this.updateInterval);
      this.chart.series[no].setData(filledData);
    },
    async addPoints() {
      setInterval(() => {
        const x = new Date().getTime();
        const setExtremes = this.chart.xAxis[0].max
          === this.chart.xAxis[0].dataMax && this.chart.fixedRange;
        try {
          this.chart.series[0].addPoint([x, parseFloat(this.getCurrent)], false, true);
          this.chart.series[1].addPoint([x, parseFloat(this.getLifetime)], false, true);
        } catch (e) {
          // empty
        }
        if (setExtremes) this.chart.xAxis[0].setExtremes(x - this.chart.fixedRange, x);
        this.chart.redraw();
      }, this.updateInterval);
    },
    fillChart() {
      this.fetchData('current', 0);
      this.fetchData('lifetime', 1);
      this.addPoints();
    },
  },
  mounted() {
    this.createChart();
    this.fillChart();
    this.$root.$on('reload', () => this.fillChart());
  },
  watch: {
    // refetch chart data when browser goes from offline to online state,
    // because highchart updates itself even in offline mode, thus
    // it does so with bad data
    getConnectionState(newVal, oldVal) {
      if (newVal === true && oldVal === false) {
        this.fillChart();
      }
    },
  },
  render(createElement) {
    return createElement('div');
  },
};
</script>
