<template>
    <div>
        <h3 class="title">{{ title }}</h3>
        <p class="message"
           :style="style">{{ message }}</p>
    </div>
</template>

<script>
export default {
  name: 'TextMessage',
  props: {
    title: String,
    message: String,
    color: String,
    size: String,
  },
  computed: {
    style() {
      return `color: ${this.color}; font-size: ${this.size}`;
    },
  },
};
</script>

<style scoped>
.title {
  display: inline-block;
  float: left;
  margin: 15px 10px 15px 0;
  font-size: 20px;
}
.message {
  display: inline-block;
  margin: 5px 0;
}

div{
  border-bottom: solid 1px white;
}
</style>
