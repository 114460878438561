<template>
  <div class="beamCol">
    <h3 class="beamHeader">BM Beamlines</h3>
    <b-table :fields="fields"
             :items="beamlines"
             borderless>
      <template v-slot:cell(name)="data" >
        <div :style="data.item.nameClass">{{data.item.name}}</div>
      </template>
      <template v-slot:cell(state)="data" >
        <div :class="data.item.class"
             :style="data.item.stateClass">{{data.item.state}} </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'BMBeamlines',
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: 'Name',
          tdClass: 'tableCell biggerFontBM whiteFont',
          thClass: 'tableCell',
        },
        {
          key: 'state',
          label: 'State',
          tdClass: 'tableCell biggerFontBM whiteFont',
          thClass: 'tableCell',
        }],
      beamlines: [
        {
          name: 'ASTRA',
          state: 'N/A',
          nameClass: 'color: #b08ade; border-bottom: 0.5px solid #b08ade',
        },
        {
          name: 'PIRX',
          state: 'N/A',
          nameClass: 'color: #6699e1; border-bottom: 0.5px solid #6699e1',
        },
        {
          name: 'POLYX',
          state: 'N/A',
          nameClass: 'color:#e65fbe; border-bottom: 0.5px solid #e65fbe',
        },
        {
          name: 'CIRI',
          state: 'under construction',
          nameClass: 'color:#a9a8ad',
          stateClass: 'color:#a9a8ad',
        },
      ],
    };
  },
  methods: {
    setFontColor(state) {
      switch (state) {
        case 'OPEN':
          return 'greenFont';
        case 'CLOSED':
          return 'redFont';
        default:
          return 'whiteFont';
      }
    },
    updateBM(no, getter) {
      this.$set(this.beamlines[no], 'state', getter);
      this.$set(this.beamlines[no], 'class', this.setFontColor(getter));
    },
  },
  mounted() {
    this.updateBM(1, this.getbl04bmStatus);
    this.updateBM(2, this.getbl09bmStatus);
    this.updateBM(0, this.getbl10bmStatus);
  },
  computed: mapGetters(['getbl04bmStatus', 'getbl09bmStatus', 'getbl10bmStatus']),
  watch: {
    getbl04bmStatus() {
      this.updateBM(1, this.getbl04bmStatus);
    },
    getbl09bmStatus() {
      this.updateBM(2, this.getbl09bmStatus);
    },
    getbl10bmStatus() {
      this.updateBM(0, this.getbl10bmStatus);
    },
  },
};
</script>

<style scoped>
</style>
