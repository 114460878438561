<template>
<div class="header">
  <div class="img">
    <img src="../assets/asm_black_EN.png"
         alt="Solaris logo">
  </div>
  <div class="text">
    <p id="title">SOLARIS Machine Status Portal</p>
  </div>
  <div class="widget">
    <p id="clock">{{ dateAndTime }}</p>
  </div>
</div>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      dateAndTime: '',
    };
  },
  methods: {
    getTime() {
      this.dateAndTime = this.$moment().format('dddd, MMMM Do YYYY, h:mm a');
    },
  },
  mounted() {
    setInterval(this.getTime, 500);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<style scoped>
.header{
  display: flex;
  height: 9%;
  flex-flow: row nowrap;
  position: relative;
  margin: 5px 5px 0px 5px;
  padding: -5px;
  box-sizing: border-box;
  width: calc(100% - 10px);
  color: white;
  font-family: "Helvetica Neue", sans-serif;
  font-size: calc(.7em + 1vmin);
  min-font-size: 10px;
}

.widget{
  width: 30%;
  margin-top: 10px;
}

#clock{
  font-size: calc(0.5em + 1vmin);
  padding-right: 10px;
  float: right;
}

.text {
  width: 40%;
  word-wrap: break-word;
  text-align: center;
  margin-top: 10px;
}

.img{
  position: relative;
  width: 30%;
}

img{
  display: inline-block;
  float:left;
  padding: 5px 5px 0px 0px;
  max-width:100%;
  max-height:90%;
}

p {
  display: inline-block;
}

@media (min-width: 1000px){
  #title {
    font-size: calc(1em + 1vmin);
  }
}

@media (min-width: 1450px){
  #title {
    font-size: calc(1.5em + 1vmin);
  }
}

</style>
