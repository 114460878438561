<template>
  <div id="app">
    <TangoGQL style="display:none"/>
    <Dashboard/>
  </div>
</template>
<script>
import Dashboard from './components/Dashboard.vue';
import TangoGQL from './components/TangoGQL.vue';

export default {
  components: { Dashboard, TangoGQL },
};
</script>

<style lang="scss">

html, body {
  height: 100vh;
  max-width: 100vw;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  overflow-y: hidden;
}

body{
  position: relative;
  background: #ecf2f9;
}

#app {
  position: absolute;
  width: 100%;
  height: 100%;
}

</style>
