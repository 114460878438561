<template>
  <div/>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { AttributesMap, DEVICE, GET_ATTR } from '../constants.js';

export default {
  name: 'TangoGQL',
  computed: mapGetters(['getConnectionState']),
  created() {
    Object.keys(this.$store.state).forEach((key) => {
      if (key !== 'connection') {
        this.createQuery(key, DEVICE + AttributesMap[key]);
      }
    });
  },
  methods: {
    ...mapActions(['updateAttr', 'setPollingSuccessStatus']),
    createQuery(name, attr) {
      this.$apollo.addSmartQuery(name, {
        query: GET_ATTR,
        variables() {
          return { attr };
        },
        pollInterval: 1000,
        update: (data) => data,
        result({ data, networkStatus }) {
          this.setConnectionStatus(networkStatus);
          if (data) {
            this.updateAttr([name, data.attributes[0].value]);
          }
        },
      });
    },
    /**
     * Checks if apollo returned network error when polling and sets polling status
     * to false if network error occurred or true otherwise.
     * Setting polling status to false also sets getConnectionState to false.
     *
     * 'isConnectionLost' secures from unnecessary setting poll status
     * when connection is established.
     * @param {Number} gqlNetworkStatus: Network status returned by Apollo library. Error == 8.
     */
    setConnectionStatus(gqlNetworkStatus) {
      const isGqlNetworkError = gqlNetworkStatus === 8;
      const isConnectionLost = !this.getConnectionState;
      if (isGqlNetworkError) {
        this.setPollingSuccessStatus(false);
      } else if (isConnectionLost) {
        this.setPollingSuccessStatus(true);
      }
    },
  },
};
</script>

<style scoped>

</style>
