import Vue from 'vue';

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import moment from 'moment';
import App from './App.vue';
import { store } from './store';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'regenerator-runtime/runtime';
import { createProvider } from './vue-apollo';

Vue.config.devtools = true;

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

Object.defineProperty(Vue.prototype, '$moment', { value: moment });

Vue.config.productionTip = false;

new Vue({
  store,
  apolloProvider: createProvider(),
  render: (h) => h(App),
}).$mount('#app');
