<template>
  <div class="cntr">
    <Header class="head"/>
    <ParameterDashboard class="param"/>
    <BeamlineDashboard class="bms"/>
    <HighChart class="chart"/>
    <MessageDashboard class="msg"/>
  </div>
</template>

<script>
import Header from './Header.vue';
import ParameterDashboard from './ParameterDashboard.vue';
import BeamlineDashboard from './BeamlineDashboard.vue';
import MessageDashboard from './MessageDashboard.vue';
import HighChart from './HighChart.vue';

export default {
  name: 'Dashboard',
  components: {
    HighChart, Header, ParameterDashboard, BeamlineDashboard, MessageDashboard,
  },
  methods: {
    updateConnectionStatus() {
      this.$store.dispatch('setNetworkStatus', navigator.onLine);
    },
  },
  created() {
    window.addEventListener('online', this.updateConnectionStatus);
    window.addEventListener('offline', this.updateConnectionStatus);
  },
  beforeDestroy() {
    window.removeEventListener('online', this.updateConnectionStatus);
    window.removeEventListener('offline', this.updateConnectionStatus);
  },
};
</script>

<style>
:root{
  --main-bg-color: #1d1e1f;
  --labelb-color: #1d1e1fcc;
}

.tableCell {
  background: var(--main-bg-color);
  color: white;
  text-align: center;
  word-break: break-word;
  white-space: nowrap;
  margin-top: -10px
}

.smallCell{
  width:auto;
  white-space: nowrap;
}

.textLeft{
  text-align: left;
}

.biggerFontBM {
  font-size: 25px;
}

.fontOP {
  font-size: 20px;
}

.smallFontOP{
  font-size: 20px;
}

.whiteFont{
  color: white;
}

.greyFont{
  color: #a9a8ad;
}

.redFont {
  color: #ff3333;
}

.greenFont {
  color: #75b400;
}

.noDisplay{
  display: none
}

.beamCol{
  flex-flow: column wrap;
  padding-bottom: 10px;
}

.beamHeader{
  color: white;
  text-align: center;
  background-color: #3e4042;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #3e4042;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #181b1b;
}

.cntr {
  display: flex;
  flex-flow: row wrap;
  height: 100%;
  width: 100%;
  position: absolute;
  background: var(--main-bg-color);
  overflow-x: hidden;
  overflow-y: scroll;
  align-content: space-between;
  padding-bottom: 50px;
}

.cntr > *:not(Header){
  height: fit-content;
  display: flex;
  position: relative;
  margin: 5px 5px 0px 5px;
  padding: 5px;
  box-sizing: border-box;
  width: calc(100% - 10px);
  font-family: 'Helvetica Neue', sans-serif;
}

.head {
  min-height: 80px;
  height: 10%;
}

.chart{
  min-height: 270px;
}

.cntr > .chart .param .head{
  background: var(--main-bg-color);
}

@media (min-width: 800px) and (max-width: 1450px){
  .cntr > .head {
    order: 0;
    width: calc(100% - 10px);
  }

  .cntr > .param {
    order: 1;
    width: calc(100% - 10px);
    height: auto;
  }

  .cntr > .bms {
    order: 2;
    width: calc(100% - 10px);
  }

  .cntr > .chart {
    order: 3;
    width: calc(100% - 10px);
    min-height: 450px;
  }

  .cntr > .msg {
    order: 4;
    width: calc(100% - 10px);
    min-height: 450px;
  }
}

@media (max-width: 500px){
  .fontOP{
    font-size:15px !important;
  }

  .smallFontOP {
    font-size:15px !important;
  }
}

@media (max-width: 1800px){
  .biggerFontBM {
    font-size:calc(0.6em + 1vmin);
  }
}

@media (min-width: 1450px) and (min-height: 800px)
{
  .cntr {
    overflow: hidden !important;
  }
}

@media (min-width: 1450px)
{
  .cntr > .head {
    order: 0;
    width: calc(100% - 10px);
  }

  .cntr > .param {
    order: 1;
    width: calc(40% - 10px);
    height: auto;
    min-height: 25%;
  }

  .cntr > .bms {
    order: 2;
    width: calc(60% - 10px);
    height: auto;
    min-height: 32%;
    margin-bottom: -35px;
  }

  .cntr > .chart {
    order: 3;
    width: calc(60% - 10px);
    min-height: 50%;
    height: auto;
  }

  .cntr > .msg {
    order: 4;
    width: calc(40% - 10px);
    min-height: 50%;
    height: auto;
  }
}
</style>
