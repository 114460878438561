<template>
  <div class="beamCol">
    <h3 class="beamHeader">ID Beamlines</h3>
    <b-table :fields="fields"
             :items="beamlines"
             borderless>
      <template v-slot:cell(name)="data" >
        <div :style="data.item.nameClass">{{data.item.name}}</div>
      </template>
      <template v-slot:cell(gap)="data">
        <div :style="data.item.gapClass">{{data.item.gap}}</div>
      </template>
      <template v-slot:cell(state)="data">
        <div :class="data.item.class"
             :style="data.item.stateClass">{{data.item.state}}</div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'IDBeamline',
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: 'Name',
          tdClass: 'tableCell biggerFontBM greyFont',
          thClass: 'tableCell',
        },
        {
          key: 'gap',
          label: 'Gap',
          tdClass: 'tableCell biggerFontBM whiteFont',
          thClass: 'tableCell',
        },
        {
          key: 'state',
          label: 'State',
          tdClass: 'tableCell biggerFontBM greyFont',
          thClass: 'tableCell',
        }],
      beamlines: [
        {
          name: 'DEMETER',
          gap: '0.00 mm',
          state: 'N/A',
          nameClass: 'color: #6699e1; border-bottom: 0.5px solid #6699e1',
        },
        {
          name: 'PHELIX',
          gap: '0.00 mm',
          state: 'N/A',
          nameClass: 'color: #e3d671; border-bottom: 0.5px solid #e3d671',
        },
        {
          name: 'URANOS',
          gap: '0.00 mm',
          state: 'N/A',
          nameClass: 'color: #7ebd57; border-bottom: 0.5px solid #7ebd57',
        },
        {
          name: 'SOLCRYS',
          gap: 'N/A',
          state: 'under construction',
          gapClass: 'color: #a9a8ad',
        },
      ],
    };
  },
  methods: {
    setFontColor(state) {
      switch (state) {
        case 'OPEN':
          return 'greenFont';
        case 'CLOSED':
          return 'redFont';
        default:
          return 'whiteFont';
      }
    },
    updateBMStatus(no, getter) {
      this.$set(this.beamlines[no], 'state', getter);
      this.$set(this.beamlines[no], 'class', this.setFontColor(getter));
    },
    updateBMGap(no, getter) {
      this.$set(this.beamlines[no], 'gap', getter);
    },
  },
  mounted() {
    this.updateBMStatus(0, this.getbl04idStatus);
    this.updateBMStatus(2, this.getbl05idStatus);
    this.updateBMStatus(1, this.getbl06idStatus);
    this.updateBMGap(0, this.getbl04idGap);
    this.updateBMGap(2, this.getbl05idGap);
    this.updateBMGap(1, this.getbl06idGap);
  },
  computed: mapGetters(['getbl04idStatus', 'getbl05idStatus', 'getbl06idStatus', 'getbl04idGap', 'getbl05idGap',
    'getbl06idGap']),
  watch: {
    getbl04idStatus() {
      this.updateBMStatus(0, this.getbl04idStatus);
    },
    getbl05idStatus() {
      this.updateBMStatus(2, this.getbl05idStatus);
    },
    getbl06idStatus() {
      this.updateBMStatus(1, this.getbl06idStatus);
    },
    getbl04idGap() {
      this.updateBMGap(0, this.getbl04idGap);
    },
    getbl05idGap() {
      this.updateBMGap(2, this.getbl05idGap);
    },
    getbl06idGap() {
      this.updateBMGap(1, this.getbl06idGap);
    },
  },
};
</script>

<style scoped>
</style>
