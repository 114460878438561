<template>
  <div>
    <div class="smallScreen">
      <p class="title">{{ title }}</p>
      <p class="value"
         :style="style"> {{ value }} {{ unit }}</p>
    </div>
    <div class="bigScreen" >
      <p class="title">{{ title }}</p>
      <p class="value"
         :style="style"> {{ value }} {{ unit }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Parameter',
  props: {
    title: String,
    value: Number(0),
    unit: String,
    color: String,
  },
  computed: {
    style() {
      return `color: ${this.color}`;
    },
  },
};
</script>

<style scoped>

  @media (max-width: 600px) {
    .smallScreen{
      display:flex;
      flex-flow: column nowrap;
      color: white;
      height: 100%;
      font-size: 15px;
    }

    .value{
      font-size: 25px;
    }

    .bigScreen{
      display:none
    }
  }

  @media (min-width: 600px) {
    .bigScreen{
      display: flex;
      flex-flow: column nowrap;
      color: white;
      font-family: 'Helvetica Neue', sans-serif;
      align-items: center;
      font-size: 3.2em;
      height: 90%;
      width: 90%;
      justify-content: center;
    }

    .smallScreen{
      display:none
    }

    .title{
      width: 70%;
      text-align: center;
      font-family: "Helvetica Neue", sans-serif;
      font-size:25px;
      border-bottom: 1px solid #3e4042;

    }
  }

  p {
    text-align: center;
    margin-bottom: 5px;
    margin-top: 5px;
  }

</style>
