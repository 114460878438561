<template>
  <div class="dashboard">
    <Parameter v-for="param in parameters"
               :key="param.id"
               :title=param.title
               :value=param.value
               :unit=param.unit
               :color=param.color>
    </Parameter>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Parameter from './Parameter.vue';

export default {
  name: 'ParameterDashboard',
  components: { Parameter },
  data() {
    return {
      parameters: [
        {
          title: 'Current', value: parseFloat('0').toFixed(2), unit: ' mA', color: '#2990c7',
        },
        {
          title: 'Energy', value: parseFloat('0').toFixed(2), unit: ' GeV', color: '#e04343',
        },
        {
          title: 'Lifetime', value: parseFloat('0').toFixed(2), unit: ' h', color: '#7ebd57',
        },
        {
          title: 'I∙τ product', value: parseFloat('0').toFixed(2), unit: ' Ah', color: '#e3d671',
        },
      ],
    };
  },
  computed: mapGetters(['getCurrent', 'getEnergy', 'getLifetime', 'getBeamDose']),
  watch: {
    getCurrent() {
      this.parameters[0].value = this.getCurrent != null ? this.getCurrent : parseFloat('0').toFixed(2);
    },
    getEnergy() {
      this.parameters[1].value = this.getEnergy;
    },
    getLifetime() {
      this.parameters[2].value = this.getLifetime != null ? this.getLifetime : parseFloat('0').toFixed(2);
    },
    getBeamDose() {
      this.parameters[3].value = this.getBeamDose;
    },
  },
};
</script>

<style scoped>
.dashboard {
  flex-flow: row wrap;
}
.dashboard > * {
  width: 50%;
  height: 50%;
  display: flex;
  justify-content: center;
}
</style>
