<template>
  <div class="dashboard">
    <TextMessage v-for="msg in messages"
                 :key="msg.id"
                 :title=msg.title
                 :message=msg.message
                 :color="msg.color"
                 :size="msg.size">
    </TextMessage>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TextMessage from './TextMessage.vue';

export default {
  name: 'TextMessageDashboard',
  components: { TextMessage },
  data() {
    return {
      messages: [
        {
          title: 'Storage Ring Status: ', message: '', color: '', size: '30px',
        },
        {
          title: 'Operation Mode: ', message: '', color: '', size: '30px',
        },
        {
          title: 'Next injections: ', message: '', color: '#f9f39f', size: '25px',
        },
      ],
      operationMode: 'unknown',
      nextInjectionsMessage: 'unknown',
      storageRingStatus: 'unknown',
    };
  },
  methods: {
    updateTextMessage(no, getter) {
      this.messages[no].message = getter;
    },
    updateMessageAndColor(no, getter) {
      this.updateTextMessage(no, getter);
      switch (getter.toLowerCase()) {
        case 'beam delivered':
        case 'beam stored':
          this.messages[no].color = '#2990c7';
          break;
        case 'ring standby':
          this.messages[no].color = '#ffff80';
          break;
        case 'injection in progress':
        case 'shutdown':
          this.messages[no].color = '#ff3333';
          break;
        case 'user operation':
        case 'machine day':
          this.messages[no].color = '#7ebd57';
          break;
        default:
          this.messages[no].color = '#ffffff';
      }
    },
  },
  mounted() {
    this.updateMessageAndColor(0, this.getStorageRingStatus);
    this.updateMessageAndColor(1, this.getOperationMode);
    this.updateTextMessage(2, this.getNextInjectionsMessage);
  },
  computed: mapGetters(['getNextInjectionsMessage', 'getStorageRingStatus', 'getOperationMode']),
  watch: {
    getStorageRingStatus() {
      this.updateMessageAndColor(0, this.getStorageRingStatus);
    },
    getOperationMode() {
      this.updateMessageAndColor(1, this.getOperationMode);
    },
    getNextInjectionsMessage() {
      this.updateTextMessage(2, this.getNextInjectionsMessage);
    },
  },
};
</script>

<style scoped>
  .dashboard{
    flex-flow: column nowrap;
    justify-content: space-around;
    height: 50%
  }
</style>
